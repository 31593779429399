import { QRCodeSVG } from "qrcode.react";
import { useEffect, useState } from "react";

function App() {
  let url = window.location.href
  const tabs = ['文字', '文件']
  const inputTip = '点击选择文件'
  const [tabIdx, setTabIdx] = useState(0)
  const [text, setText] = useState('')
  const [file, setFile] = useState(null)
  const [textInputTip, setTextInputTip] = useState(inputTip)
  const [list, setList] = useState([])
  const [link, setLink] = useState('')

  const formatDate = (dstr) => {
    const date = new Date(dstr)
    const hour = date.getHours()
    const minute = date.getMinutes()
    let str = '今天'
    if (new Date().getDate() > date.getDate()) {
      str = '昨天'
    }
    if (hour < 12) {
      str += '上午'
    } else {
      str += '下午'
    }

    return `${str}${hour < 10 ? '0' + hour : hour}:${minute < 10 ? '0' + minute : minute}`
  }

  const fetchData = async () => {
    fetch('/api/board').then(response => response.json())
      .then(json => {
        if (json.data.length > 0) {
          setList(json.data)
        }
      })
  }

  const getLink = (board) => {
    return url + 'b/' + board.k
  }

  useEffect(() => {
    fetchData()
  }, [])

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      const f = e.target.files[0]
      if (f.size > 2 * 1024 * 1024) {
        alert('文件尺寸过大')
      } else {
        setFile(f)
        setTextInputTip(f.name)
      }
    }
  }

  const handleSub = async () => {
    if (text.length < 5 && !file) {
      alert('提交内容不能为空')
      return
    }
    const data = new FormData();
    data.append('file', file);
    data.append('text', text);
    const response = await fetch('/api/board', {
      method: 'POST',
      body: data
    });
    const json = await response.json();
    console.log(json);
    if (json.code !== 0) {
      alert('提交失败')
      return
    }
    setLink(getLink(json.data))
    setText('')
    setFile(null)

    await fetchData()
  }

  const renderTabs = () => {
    return (
      <ul className="flex mb-4">
        {
          tabs.map((val, index) => <li key={index} className={`btn-base mr-4 ${index === tabIdx ? "text-white bg-gray-400" : null}`} onClick={() => setTabIdx(index)}>{val}</li>)
        }
      </ul >
    );
  }

  const renderTextInput = () => {
    return (
      <div className="ipt-text">
        <textarea value={text} onChange={(e) => setText(e.target.value)} className="resize-none border rounded-md w-full focus:outline-none px-2 py-1" cols="50"
          rows="5"></textarea>
        <div className="flex justify-between items-center">
          <p className="tips-text text-sm text-gray-400">*请输入5~1000个字符</p>
          <button onClick={handleSub} className="border rounded-md shadow-md text-gray-500 text-base px-2 cursor-pointer w-16"
          >确定
          </button>
        </div>
      </div>
    );
  }

  const renderFileInput = () => {
    return (
      <div className="ipt-file flex md:items-center flex-col md:flex-row">
        <div>
          <input onChange={(e) => handleFileChange(e)} id="file" type="file" className="opacity-0 absolute w-0.5 h-0.5" />
          <label htmlFor="file" className="block w-full md:max-w-xs rounded-md shadow-md bg-green-200 text-white px-2 py-1 overflow-hidden overflow-ellipsis whitespace-nowrap">{textInputTip}</label>
        </div>
        <div className="flex items-center md:mt-0 mt-2">
          <button onClick={handleSub} className="btn-base border text-gray-500 md:ml-4 mr-2 w-16"
          >确定
          </button>
          <p className="tips-text text-sm text-gray-400">*最大上传2mb</p>
        </div>
      </div>
    );
  }

  const renderQrcode = () => {
    if (link) {
      return (
        <div id="qrcode" className="ml-1 mt-2 md:flex items-baseline">
          <QRCodeSVG value={link} />
          <div className="flex items-center mt-2">
            <p className="md:mx-2 text-gray-600">您的链接:</p>
            <a className="text-green-300" href={link} target="_blank" rel="noreferrer">{link}</a>
          </div>
        </div>
      )
    }
  }

  return (
    <div className="md:container mx-auto p-6 md:py-6">
      <div className="new">
        <div className="mb-6 md:flex items-end">
          <h1 className="text-xl text-gray-900 font-bold">云剪切板</h1>
          <p className="md:ml-2 text-tip">一个无依赖即用即走的剪切板，支持 web curl</p>
        </div>

        {renderTabs()}

        <div className="md:w-2/5">
          {tabIdx === 0 ? renderTextInput() : renderFileInput()}
        </div>
      </div>

      {renderQrcode()}

      <div className="help mt-6">
        <p className="text-gray-600 text-base">*使用说明:</p>
        <p className="text-tip">1. 提交文字或上传文件后得到一个唯一链接，例如 {url}b/xxxx</p>
        <p className="text-tip">2. 命令行提交文字 curl {url}api/board -d "text=示例文字"</p>
        <p className="text-tip">3. 命令行上传文件 curl {url}api/board -F "file=@some.file"</p>
        <p className="text-tip">4. 使用时，对于文字 curl {url}b/xxxx 或者 浏览器打开链接</p>
        <p className="text-tip">5. 使用时，对于文件 curl -O {url}b/xxxx 或者 浏览器打开链接下载</p>
        <p className="text-tip">6. 历史记录为48h内最近20条，依靠cookie标记</p>
      </div>
      <div className="my-6">
        <p className="text-gray-600 text-base">*历史记录:</p>
        {
          list.map((item, index) => <p
              key={index}
              onClick={() => setLink(getLink(item))}
              className="text-tip cursor-pointer">{index + 1}. {formatDate(item.created_at)} {item.t === 1 ? '文字': '文件'} {getLink(item)}</p>)
        }
      </div>
    </div>
  );
}

export default App;
